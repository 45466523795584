import React, {Component} from 'react';
import Chart from "chart.js";
import Api from "../../../libraries/api";
import {CircularProgress} from "@material-ui/core";

class ChartDevice extends Component {
    constructor(props) {
        super(props);
        this.chartRef = React.createRef();
        this.state = {
            period: 'weekly',
            loading: false
        };
        this.getDeviceChart('weekly')
        this.handleChange = this.handleChange.bind(this);
        this.getDeviceChart = this.getDeviceChart.bind(this);
    }

    getDeviceChart(period) {
        Api.get('/reports/user-device?period=' + period, true).then(resp => {
            if (resp.data) {
                let labels = resp.data["userDevice"]["columns"];
                let android = resp.data["userDevice"]["android"];
                let ios = resp.data["userDevice"]["ios"];
                this.setState({loading: false})
                this.__chart(labels, android, ios)
            }
        }).catch(err => {
            console.log(err);
        });
    }

    __chart = (labels, android, ios) => {
        var barChartData = {
            labels: labels,
            datasets: [{
                label: 'Android',
                backgroundColor: '#008299',
                borderColor: '#008299',
                borderWidth: 1,
                hiddenLegend: true,
                datalabels: {
                    color: 'transparent'
                },
                data: android
            }, {
                label: 'Ios',
                backgroundColor: '#10d7ee',
                borderColor: '#10d7ee',
                borderWidth: 1,
                hiddenLegend: true,
                datalabels: {
                    color: 'transparent'
                },
                data: ios
            }]
        };
        let myChartRef = this.chartRef.current.getContext("2d");
        new Chart(myChartRef, {
            type: 'bar',
            data: barChartData,
            options: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true,
                            precision: 0
                        }
                    }]
                },
                legend: {
                    position: 'top',
                },
            }
        }).update();
    }

    handleChange = (target, prop) => {
        this.setState({period: target, loading: true}, () => {
            this.getDeviceChart(target)
        });
    };

    render() {
        return (
            <div>
                <div className="col-12" style={{marginTop:'20px',padding:'0px'}}>
                    <div className="col-12 card">
                        <div className="row title-dashboard">
                            <div className="col-6">
                                <h2>Mobile Plataform</h2>
                            </div>
                        </div>
                        <div className="d-md-flex justify-content-center mt-4 mb-4">
                            <nav className="nav nav-filter">
                                <button className={"nav-link pl-0 " + (this.state.period === 'daily' && 'active')}
                                        onClick={() => this.handleChange('daily', 'period')}>Daily
                                </button>
                                <button className={"nav-link " + (this.state.period === 'weekly' && 'active')}
                                        onClick={() => this.handleChange('weekly', 'period')}>Weekly
                                </button>
                                <button className={"nav-link " + (this.state.period === 'monthly' && 'active')}
                                        onClick={() => this.handleChange('monthly', 'period')}>Monthly
                                </button>
                            </nav>
                        </div>
                        <ShowDeviceChart loading={this.state.loading} chartRef={this.chartRef}/>
                    </div>
                </div>
            </div>
        )
    }
}

class ShowDeviceChart extends Component {
    render() {
        if (this.props.loading === true) {
            return <CircularProgress/>
        } else {
            return (
                <div className="chart-container">
                    <canvas id="myChart" ref={this.props.chartRef}/>
                </div>
            )
        }
    }
}


export default ChartDevice;
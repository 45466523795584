import React,{Component} from 'react';
import SimpleReactValidator from "simple-react-validator";
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import {connect} from 'react-redux';
import {RootActions} from "../../../shared/root-action";
import {bindActionCreators} from "redux";
import Api from "../../../libraries/api";
import AuthHelper from "../../../libraries/auth-helper";
import {history} from "../../../shared/configure-store";
import ErrorHandling from "../../../libraries/error-handling";
import Logo from '../../../images/logo.png';

require('dotenv').config();

const validator = new SimpleReactValidator({locale: process.env.REACT_APP_LOCALE});

class Login extends Component {

    constructor(props) {

        super(props);

        this.state = {
            loading: false,
            errors: {},
            email: '',
            password: '',
            persistence: false,
            showPassword: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleChecked = this.handleChecked.bind(this);
        this.handleLogIn = this.handleLogIn.bind(this);

    }

    handleLogIn = e => {
        e.preventDefault();
        if (!validator.allValid()) {
            this.setState({
                    errors: validator.getErrorMessages()
                }
            );
            return false;
        }
        this.setState({
                errors: {},
                loading: true,
            }
        );
        let params = {
            email: this.state.email,
            password: this.state.password,
        };

        Api.post('/login', params, false).then(resp => {
            AuthHelper.setLogin(resp);
            AuthHelper.setProfile(resp.data.user);
            let profile = resp.data.user;
            let user = JSON.parse(JSON.stringify(profile));
            delete user.role;
            let role = JSON.parse(JSON.stringify(profile.role));
            
            delete role.permisssions;
            let permissions = JSON.parse(JSON.stringify(profile.role.permissions));
            this.props.setProfile(user);
            this.props.setRole(role);
            this.props.setPermission(permissions);
            history.push('/');
        }).catch(err => {
            if(ErrorHandling.checkErrorTokenExpired(err)){
            }else{
                if(err.errors.messages === "Trying to get property 'password' of non-object"){
                    let errors = {
                        messages:'Invalid email or password.'
                    }
                    this.setState({
                        errors: errors,
                        loading: false
                    });    
                }else{
                    this.setState({
                        errors: err.errors,
                        loading: false
                    });
                }
            }
        });
    };

    handleChange (e, prop){
        this.setState({
            [prop]: e.target.value
        })
    };

    handleChecked = name => e => {
        this.setState({
            [name]: e.target.checked ? 1 : 0,
        })
    };

    handleClickShowPassword = () => {
        this.setState({
            showPassword:(!this.state.showPassword)
        })
    };

    render() {
        return (
            <main className="login">
                <section>
                    <img src={Logo} alt="vado" className="logo" />

                    <form name="login" id="login" noValidate>

                        <div className="form-group">
                            <TextField
                                type='text'
                                id='email'
                                name="email"
                                label="Enter your email"
                                onChange={(e) => this.handleChange(e, 'email')}
                                minLength={4}
                                maxLength={50}
                                value={this.state.email}
                                fullWidth
                            />
                            {validator.message('email', this.state.email, 'email|required')}
                            <div className='text-danger'>{this.state.errors.email}</div>
                        </div>

                        <div className="form-group">
                            <TextField
                                type={this.state.showPassword ? 'text' : 'password'}
                                id="adornment-password"
                                name="password"
                                label="Enter your password"
                                onChange={(e) => this.handleChange(e,'password')}
                                value={this.state.password}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="Toggle password visibility"
                                                onClick={this.handleClickShowPassword}
                                            >
                                                {this.state.showPassword ? <i className="fas fa-eye"> </i> :
                                                    <i className="fas fa-eye-slash"> </i>}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                                fullWidth
                            />
                            {validator.message('password', this.state.password, 'required')}
                            <div className='text-danger'>{this.state.errors.password}</div>
                        </div>
                        <FormControl>
                            <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.persistence === 1}
                                        onChange={this.handleChecked('persistence')}
                                        value='persistence'
                                        color='primary'
                                    />}
                                label="Keep me signed in"
                                labelPlacement="end"
                            />
                            </FormGroup>
                        </FormControl>

                        <div className='text-danger mb-2'>{this.state.errors.messages}</div>

                        <button onClick={this.handleLogIn} className="btn btn-blue" disabled={this.state.loading && 'disabled' }>SIGN IN { this.state.loading && <i className="fa fa-spinner fa-spin"> </i> }</button>

                    </form>

                </section>
            </main>

        )

    }
}

const mapStateToProps = (state) => {

    return {

        profile_state: state.profile_state,

        role_state: state.role_state,

        permission_state: state.permission_state,

    };

};

const mapDispatchToProps = (dispatch) => {

    return bindActionCreators(RootActions, dispatch)

};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
import React, {Component} from 'react';
import {TextField} from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableSortLabel from '@material-ui/core/TableSortLabel';
import CircularProgress from "@material-ui/core/CircularProgress";
import TablePagination from "@material-ui/core/TablePagination";
import TablePaginationActions from "../../presentational/table-pagination-actions";
import Api from "../../../libraries/api";
import {history} from "../../../shared/configure-store";
import DateFormat from "../../../libraries/data-format-helper";
import ResendOtp from "./resend-otp";
import { ToastContainer } from 'react-toastify';

class Accounts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            rows: [],
            sendPageNumber: '',
            sendPerPage: '',
            perPage: 50,
            currentPage: 0,
            currentPageTable: 0,
            totalData: 0,
            search: '',
            orderBy:'',
            order: 'desc',
        }
        this.getAccountUser();
    }

    handleChange(e, prop) {
        this.setState({
            [prop]: e.target.value
        })
    };

    getAccountUser() {
        let currentPage = this.state.currentPage + 1;
        let url = "/accounts?perPage=" + this.state.perPage + "&pageNumber=" + currentPage + "&search=" + this.state.search;
        Api.get(url, false,'core').then((resp) => {
            this.setState({
                rows: resp["data"]["users"],
                loading: false,
                totalData: resp["data"]["meta"]["totalData"]
            });
        }).catch(err=>{
            this.setState({loading: false,})
            console.log("errror:",err)
        });
    }

    handleKeyPressSearch = (event) => {
        if (event.key === 'Enter') {
            this.setState({
                rows: [],
                currentPage: 0,
                currentPageTable: 0,
                loading: true,
            }, () => {
                this.getAccountUser();
            });
        }
    };

    handleChangePage = (event, newPage) => {
        this.setState({
            rows: [],
            currentPage: newPage,
            currentPageTable: newPage,
            loading: true,
        }, () => {
            this.getAccountUser();
        });
    };

    handleChangeRowsPerPage = event => {
        this.setState({
            perPage: event.target.value,
            currentPage: 0,
            currentPageTable: 0,
            loading: true,
        }, () => {
            this.getAccountUser();
        });
    };

    createSortHandler = (property) => (event) => {
        this.handleRequestSort(event, property);
      };
    
      handleRequestSort = (event, property) => {
        const isAsc = this.state.orderBy === property && this.state.order === 'desc';
        this.setState({
            orderBy:property,
            order:isAsc ? 'asc':'desc',
        })
      };
      
      descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
          return -1;
        }
        if (b[orderBy] > a[orderBy]) {
          return 1;
        }
        return 0;
      };
    
      stableSort(array, comparator){
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) {
                return order;
            }
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
      }
    
      getComparator(order, orderBy) {
        return order === 'desc'
            ? (a, b) => this.descendingComparator(a, b, orderBy)
            : (a, b) => -this.descendingComparator(a, b, orderBy);
      }

    render() {
        return (
            <div className="" >
                 <ToastContainer/>
                <h2 className='text-title-big' style={{marginBottom:'20px'}}>Accounts List</h2>
                <div className="card col-md-12 col-lg-12">
                    <div className="row">
                        <div className="col search-bar">
                            <TextField
                                type="text"
                                id="adornment-search"
                                name="search"
                                placeholder="Search by name or account number"
                                onChange={(e) => this.handleChange(e, 'search')}
                                onKeyPress={this.handleKeyPressSearch}
                                value={this.state.search}
                                variant="outlined"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <IconButton
                                                aria-label="Toggle password visibility"
                                                onClick={this.handleClickSearch}
                                            >
                                                <i className="fas fa-search"> </i>
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                                fullWidth
                            />
                        </div>
                        <ResendOtp newUser={true}/>
                    </div>
                    <div className="table-wrapper">
                        <Table className="table-list mt-3" size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell >
                                        <TableSortLabel active={this.state.orderBy === "createdAt" }
                                                        direction={this.state.orderBy === "createdAt" ? this.state.order : 'asc'}
                                                        onClick={this.createSortHandler("createdAt")}   
                                        >
                                            Created Date
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel active={this.state.orderBy === "id" }
                                                        direction={this.state.orderBy === "id" ? this.state.order : 'asc'}
                                                        onClick={this.createSortHandler("id")}   
                                        >
                                            Acct No.
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel active={this.state.orderBy === "firstName" }
                                                        direction={this.state.orderBy === "firstName" ? this.state.order : 'asc'}
                                                        onClick={this.createSortHandler("firstName")}   
                                        >
                                            First Name
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel active={this.state.orderBy === "lastName" }
                                                        direction={this.state.orderBy === "lastName" ? this.state.order : 'asc'}
                                                        onClick={this.createSortHandler("lastName")}   
                                        >
                                            Last Name
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel active={this.state.orderBy === "phone" }
                                                        direction={this.state.orderBy === "phone" ? this.state.order : 'asc'}
                                                        onClick={this.createSortHandler("phone")}   
                                        >
                                            Mobile
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel active={this.state.orderBy === "email" }
                                                        direction={this.state.orderBy === "email" ? this.state.order : 'asc'}
                                                        onClick={this.createSortHandler("email")}   
                                        >
                                            Email
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel active={this.state.orderBy === "activatedDate" }
                                                        direction={this.state.orderBy === "activatedDate" ? this.state.order : 'asc'}
                                                        onClick={this.createSortHandler("activatedDate")}   
                                        >
                                            Activated Date
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel active={this.state.orderBy === "transponderNumber" }
                                                        direction={this.state.orderBy === "transponderNumber" ? this.state.order : 'asc'}
                                                        onClick={this.createSortHandler("transponderNumber")}   
                                        >
                                            Toll Tag
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel active={this.state.orderBy === "balance" }
                                                        direction={this.state.orderBy === "balance" ? this.state.order : 'asc'}
                                                        onClick={this.createSortHandler("balance")}   
                                        >
                                            Current Balance
                                        </TableSortLabel>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            {this.state.loading ?
                                (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell colSpan={9} align="center" className="py-5">
                                                <CircularProgress color="primary"/>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                ) :
                                (
                                    <TableBody>
                                    {
                                    //this.props.rows.map(function (item, i) {
                                    this.stableSort(this.state.rows, this.getComparator(this.state.order, this.state.orderBy))
                                        .map((item, i)=>{
                                        let transponderStatus = item.transponderStatus;
                                        let tollTagStatus = (transponderStatus === 'sent') ? 'shipped' : transponderStatus
                                        return (
                                            <TableRow key={i} onClick={() => history.push('/accounts/detail/' + item.id)}>
                                                <TableCell>{DateFormat.dateTime(item.createdAt)}</TableCell>
                                                <TableCell>{item.id}</TableCell>
                                                <TableCell>{item.firstName}</TableCell>
                                                <TableCell>{item.lastName}</TableCell>
                                                <TableCell>{item.phone}</TableCell>
                                                <TableCell>{item.email}</TableCell>
                                                {(item.transponderNumber === null) ?
                                                    (<TableCell></TableCell>) :
                                                    (<TableCell>{DateFormat.dateTime(item.activatedDate)}</TableCell>)
                                                }
                                                {(item.transponderNumber === null) ?
                                                    (<TableCell>{tollTagStatus}</TableCell>) : (
                                                        <TableCell>{item.transponderNumber}</TableCell>)
                                                }
                                                <TableCell>${item.balance}</TableCell>
                                            </TableRow>
                                        )
                                    })}
                                    </TableBody>
                                )
                            }
                        </Table>
                    </div>
                    <div>
                        <TablePagination
                            rowsPerPageOptions={[50, 75, 100]}
                            component="div"
                            count={this.state.totalData}
                            rowsPerPage={this.state.perPage}
                            page={this.state.currentPageTable}
                            backIconButtonProps={{
                                'aria-label': 'previous page',
                            }}
                            nextIconButtonProps={{
                                'aria-label': 'next page',
                            }}
                            onChangePage={this.handleChangePage}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default Accounts